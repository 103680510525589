/* eslint-disable no-await-in-loop */
import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import useForm from 'react-hook-form'
import styled, { keyframes } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import { MdSync } from 'react-icons/md'
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3'

const recaptchaClientKey = '6LeXiMEUAAAAAHDZuNQJEfEVD7lpcjvwoLWxriBK'

import Layout from 'components/Layout'

const asyncForEach = async (array, callback) => {
  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array)
  }
}

export const Container = styled.div`
  max-width: 1150px;
  min-height: 300px;
  margin: 0 auto;
  position: relative;
`
const Overlay = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  tight: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  width: 100%;
`

const Form = styled.form`
  max-width: 500px;
  margin: 0 auto;

  p.error {
    color: #bf1650;
  }

  p.error::before {
    display: inline;
    content: '⚠ ';
  }
`

const Input = styled.input`
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 2px solid ${themeGet('colors.primary')};
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
  outline: none;

  &:focus {
    border: 2px solid ${themeGet('colors.orange')};
  }

  &[type='checkbox'] {
    display: inline;
  }

  &[type='submit'] {
    color: ${themeGet('colors.white')};
    background-color: ${themeGet('colors.primary')};
    text-transform: uppercase;
    border: none;
    margin-top: 40px;
    padding: 20px;
    font-size: 16px;
    font-weight: 100;
    letter-spacing: 10px;

    &:hover {
      background-color: ${themeGet('colors.orange')};
    }

    &:disabled {
      background-color: ${themeGet('colors.gray')};
    }
  }
`
const Select = styled.select`
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 2px solid ${themeGet('colors.primary')};
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
  outline: none;
`

const Label = styled.label`
  line-height: 2;
  text-align: left;
  display: block;
  margin-bottom: 13px;
  margin-top: 20px;
  font-size: 14px;
  font-weight: bold;
`
const Checkmark = styled.span`
  position: absolute;
  top: 3px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;

  &:after {
    content: '';
    position: absolute;
    display: none;
  }
`

const Checkbox = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &:hover input ~ ${Checkmark} {
    background-color: #ccc;
  }
  & input:checked ~ ${Checkmark} {
    background-color: ${themeGet('colors.primary')};
  }
  & input:checked ~ ${Checkmark}:after {
    display: block;
  }
  & ${Checkmark}:after {
    left: 9px;
    top: 5px;
    width: 7px;
    height: 11px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`

const H2 = styled.h2`
  margin-top: 2rem;
`
const rotate = keyframes`
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
`

const Rotate = styled.div`
  display: inline-block;
  animation: ${rotate} 2s linear infinite;
  line-height: 0;
  font-size: 48px;
  color: ${themeGet('colors.orange')};
`

const errMessages = {
  exceedsFileSize: 'Файлът е по-голям от разрешените 10Mb.',
  exceedsFilesMax: 'Избрали сте повече от 5 файлал',
}

const TestPage = ({ data }) => {
  const { footerData = {}, headerData = {} } = data
  let hData = {}
  if (headerData.edges && headerData.edges.length > 0) {
    hData = headerData.edges[0].node.frontmatter
  }

  let fData = {}
  if (footerData.edges && footerData.edges.length > 0) {
    fData = footerData.edges[0].node.frontmatter
  }

  const [working, setWorking] = useState(false)
  const [success, setSuccess] = useState(false)
  const [recaptchaToken, setRecaptchaToken] = useState()

  const verifyCallback = recaptchaToken => {
    // Here you will get the final recaptchaToken!!!
    setRecaptchaToken(recaptchaToken)
  }

  useEffect(() => {
    loadReCaptcha(recaptchaClientKey)
  })

  useEffect(() => {
    if (success && window) {
      window.scrollTo(0, 0)
    }
  }, [success])

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setError,
    setValue,
  } = useForm()

  const getPresignedPostData = (file, folder) => {
    return new Promise((resolve, reject) => {
      fetch('/.netlify/functions/form-gladnici', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: file.name,
          folder,
        }),
      })
        .then(response => response.json())
        .then(json => {
          resolve(json)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const uploadFileToS3 = (presignedPostData, file) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('acl', 'public-read')
      Object.keys(presignedPostData.fields).forEach(key => {
        formData.append(key, presignedPostData.fields[key])
      })

      // Actual file has to be appended last.
      formData.append('file', file)

      fetch(presignedPostData.url, {
        method: 'POST',
        body: formData,
      })
        .then(response => resolve(response))
        .catch(error => {
          reject(error)
        })
    })
  }

  const saveFormData = data => {
    const {
      schoolName,
      city,
      teacherName,
      grade,
      summary,
      application,
      imageURLs = {}
    } = data

    return new Promise((resolve, reject) => {
      fetch('/.netlify/functions/form-gladnici-save', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          schoolName,
          city,
          teacherName,
          grade,
          summary,
          application,
          ...imageURLs,
          recaptchaToken
        }),
      })
        .then(response => response.json())
        .then(json => {
          resolve(json)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  const onSubmit = async formData => {
    setWorking(true)

    const { image, applicationFile } = formData

    const { data: appplicationFilePostData } = await getPresignedPostData(
      applicationFile[0]
    )

    await uploadFileToS3(appplicationFilePostData, applicationFile[0])

    const imageURLs = {}

    await asyncForEach(image, async (imageItem, i) => {
      // await Array.from(image).forEach(async (imageItem, i) => {
      const { data: postData } = await getPresignedPostData(
        imageItem,
        appplicationFilePostData.folder
      )
      await uploadFileToS3(postData, imageItem)
      imageURLs[`image${i + 1}`] = postData.assetUrl
      if (i === image.length - 1) {
        // https://lidl-umnici-gladnici.s3.eu-central-1.amazonaws.com/6b9k2okp1aa/6b9k2okp1ab_KL_Logo.pdf
        await saveFormData({
          ...formData,
          application: appplicationFilePostData.assetUrl,
          imageURLs
        })
        setWorking(false)
        setSuccess(true)
      }
    })
  }

  useEffect(() => {
    register({ name: 'isAgreedWithTerms' }, { required: true })
    register({ name: 'applicationFile' }, { required: true })
    register({ name: 'image' }, { required: true })
  }, [register])

  const handleCheckboxChange = e => {
    setValue('isAgreedWithTerms', e.target.checked) // you get all the files object here
  }

  const handleFileChange = e => {
    if (e.target.files) {
      Array.from(e.target.files).forEach(file => {
        if (file.size > 10000000) {
          setError(
            e.target.name,
            'exceedsFileSize',
            errMessages.exceedsFileSize
          )
        }
      })
    }
    if (e.target.files.length > 5) {
      setError(e.target.name, 'exceedsFilesMax', errMessages.exceedsFilesMax)
    }
    setValue(e.target.name, e.target.files)
  }

  const isAgreedWithTerms = watch('isAgreedWithTerms')

  return (
    <Layout headerData={hData} footerData={fData}>
      <Container>
        {/* "handleSubmit" will validate your inputs before invoking "onSubmit" */}
        {success ? (
          <H2 style={{ textAlign: 'center' }}>Данните са изпратени успешно!</H2>
        ) : (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <h1>Форма за кандидатстване</h1>
            <p>
              Инициатива на Лидл България за здравословно и балансирано хранене
              „Умници гладници“.
            </p>
            {/* register your input into the hook by invoking the "register" function */}
            <Label>Име на училище</Label>
            <Input name="schoolName" ref={register({ required: true })} />
            {errors.schoolName && (
              <p className="error">Това поле е задължително</p>
            )}

            <Label>Град</Label>
            <Input name="city" ref={register({ required: true })} />
            {errors.city && <p className="error">Това поле е задължително</p>}

            <Label>Име на преподавател, който е провел урок</Label>
            <Input name="teacherName" ref={register({ required: true })} />
            {errors.teacherName && (
              <p className="error">Това поле е задължително</p>
            )}

            <Label>Клас, в който е реализиран урока</Label>
            <Select
              name="grade"
              ref={register({ required: true, min: 1, max: 4 })}
            >
              <option value="0">Изберете клас</option>
              <option value="1">I клас</option>
              <option value="2">II клас</option>
              <option value="3">III клас</option>
              <option value="4">IV клас</option>
            </Select>
            {errors.grade && <p className="error">Това поле е задължително</p>}

            <Label>
              Кратко описание на методическата разработка на урок за
              здравословно и балансирано хранене - тема, подход и включването на
              учениците (до … символа)
            </Label>
            <Input name="summary" ref={register({ required: true })} />
            {errors.summary && (
              <p className="error">Това поле е задължително</p>
            )}

            <H2>Документация за кандидатстване</H2>
            <p>
              Прикачете необходимите файлове в word или pdf формат за текстови
              файлове и jpg за снимкови.
            </p>
            <Label>Формуляр за кандидатстване в конкурс</Label>
            <Input
              type="file"
              name="applicationFile"
              onChange={handleFileChange}
              accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            />
            {errors.applicationFile && (
              <p className="error">{errors.applicationFile.message}</p>
            )}
            <Label>Снимка</Label>
            <p>Прикачете до 5 снимки с максимален размер 10Mb.</p>
            <Input
              type="file"
              name="image"
              onChange={handleFileChange}
              accept="image/*"
              multiple
            />
            {errors.image && <p className="error">{errors.image.message}</p>}
            <Checkbox>
              <input
                type="checkbox"
                name="isAgreedWithTerms"
                defaultValue={false}
                onChange={handleCheckboxChange}
              />
              Съгласен съм с правилата на инициатива „Умници гладници“. Съгласен
              съм личните ми данни да бъдат обработвани за целите на играта,
              съгласно политиката на Лидл България за защита на личните данни.
              <Checkmark />
            </Checkbox>

            <Input
              type="submit"
              value="изпрати"
              disabled={!isAgreedWithTerms || working}
            />
          </Form>
        )}
        {working && (
          <Overlay>
            <Rotate>
              <MdSync />
            </Rotate>
          </Overlay>
        )}
        <ReCaptcha
          sitekey={recaptchaClientKey}
          action="formSubmit"
          verifyCallback={verifyCallback}
        />
      </Container>
    </Layout>
  )
}

export default TestPage

export const testPageQuery = graphql`
  query TestPage {
    ...LayoutFragment
  }
`
